import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import React from 'react';

import jetskiData from '../../data/jetski.json';
import Gallery from '../../components/Gallery';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const JetSkis = ({ prices = false }) => {
    return (
        <>
            <Helmet>
                <title>JETSKI RENTAL | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Feel the rush as you speed across the waves on our latest models, the fastest jet skis available. Experience the adrenaline and exhilaration with us." />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">
                    <h2>JETSKI</h2>

                    <p>Experience the ultimate thrill of our high-speed jet skis as you race across Fig Tree Bay! Feel
                        the rush of their powerful engines propelling you to exhilarating speeds, sure to get your
                        adrenaline pumping. No need to worry – our trained and experienced personnel will guide you on
                        operating the craft and inform you of all safety procedures, ensuring a safe and exhilarating
                        ride. Whether you're riding solo, with a partner, or enjoying some family fun, our jet skis
                        guarantee an unforgettable adventure. Drivers must be 18 or older, while younger children can
                        join in on the excitement with an accompanying adult. Don't miss out on the excitement – come
                        ride with us!</p>

                    {prices ? (
                        <>
                            <div className="pricelist">
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> 15 minutes: <span className='price'>€50</span></span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> 20 minutes: <span className='price'>€60</span></span>
                                    {/*<span><FontAwesomeIcon className="icon" icon={faAngleRight} /> 30 minutes: €90</span>*/}
                            </div>
                        </>
                    ) : null}


                    <Gallery data={jetskiData} className="grid" />
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default JetSkis;
