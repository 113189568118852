import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

import '../css/about.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>CONTACT | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Contact XS Watersports for an unforgettable aquatic adventure in Protaras, Cyprus. Our expert team is ready to assist you with jet skiing, parasailing, paddleboarding, and more. Reach out today for information on bookings and inquiries." />
            </Helmet>

            <Navigation active="CONTACT"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">
                    <Link className="contact-link" to="https://maps.app.goo.gl/KTTquSrHUVGnaWrU9" target="_blank">
                        <FontAwesomeIcon className="contact-icon" icon={faLocationDot} /> Sunrise Beach, Protaras
                        Cyprus
                    </Link>
                    <iframe
                        title='map'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5495.47966664426!2d34.05484385221139!3d35.01592206305522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14dfc404b4ac2655%3A0x4ddb4f8d9ddfb6d7!2sXS%20Watersports!5e0!3m2!1sen!2s!4v1685207464382!5m2!1sen!2s"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>

                    <div className="contact-details">
                        <h2>CONTACT US</h2>
                        <p>For further information please do not hesitate to contact us.</p>



                        <h3>XS Watersports</h3>

                        <Link to="tel:+35799612229" className="contact-link">
                            <FontAwesomeIcon className="contact-icon" icon={faPhone} /> (+357) 99612229
                        </Link>
                        <Link to="https://wa.link/apvsvx" target="_blank" className="contact-link">
                            <FontAwesomeIcon className="contact-icon" icon={faWhatsapp} /> (+357) 99612229
                        </Link>

                        <Link to={'mailto:xswatersports@cytanet.com.cy'} className="contact-link">
                            <FontAwesomeIcon className="contact-icon" icon={faEnvelope} /> xswatersports@cytanet.com.cy
                        </Link>

                        <br />

                        <h3>Stephanos Watersports</h3>

                        <Link to="tel:+35799567375" className="contact-link">
                            <FontAwesomeIcon className="contact-icon" icon={faPhone} /> (+357) 99567375
                        </Link>
                        <Link to="https://api.whatsapp.com/send?phone=35799567375" target="_blank"
                              className="contact-link">
                            <FontAwesomeIcon className="contact-icon" icon={faWhatsapp} /> (+357) 99567375
                        </Link>
                        <Link to={'mailto:stephanoswatersports@gmail.com'} className="contact-link">
                            <FontAwesomeIcon className="contact-icon"
                                             icon={faEnvelope} /> stephanoswatersports@gmail.com
                        </Link>


                        <h2>SOCIAL NETWORKS</h2>

                        <Link className="contact-link" to="https://www.youtube.com/user/xswatersports" target="_blank">
                            <FontAwesomeIcon className="contact-icon" icon={faYoutube} /> Youtube
                        </Link>
                        <Link className="contact-link" to="https://www.facebook.com/XSWatersportsCyprus"
                              target="_blank">
                            <FontAwesomeIcon className="contact-icon" icon={faFacebook} /> Facebook
                        </Link>
                        <Link className="contact-link" to="https://www.instagram.com/xswatersports/" target="_blank">
                            <FontAwesomeIcon className="contact-icon" icon={faInstagram} /> Facebook
                        </Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Contact;
