import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

import lessonData from '../../data/lesson.json';
import Gallery from '../../components/Gallery';
import { Helmet } from 'react-helmet';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Lesson = ({ prices = false }) => {
    return (
        <>
            <Helmet>
                <title>Lesson (Waterski & Wakeboard) | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Learn with ease using our innovative boom system, designed for stability and progression. Our expert guides provide personalized instruction, ensuring you master waterskiing or wakeboarding with confidence and excitement" />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">
                    <h2>Lesson (Waterski & Wakeboard)</h2>

                    <p>At XS Watersports, our waterski and wakeboard lessons are backed by the expertise of our
                        instructors certified by the British Waterski Association (BWSW). Utilizing our innovative boom
                        system for stability and progression, learning becomes effortless under their guidance. Our
                        expert guides provide personalized instruction tailored to your skill level and goals, ensuring
                        you master waterskiing or wakeboarding with confidence and excitement. Whether you're a beginner
                        or looking to refine your technique, our instructors are dedicated to your progress. With a
                        focus on safety and fun, our lessons incorporate the latest teaching methods and top-of-the-line
                        equipment, guaranteeing a rewarding and memorable experience on the water for all.</p>

                    {prices ? (
                        <>
                            <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> 5km / 20-30 minutes : <span className='price'>€50</span></span>
                            </div>
                        </>
                    ) : null}

                    <Gallery data={lessonData} className="grid" />
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Lesson;
