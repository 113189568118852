import Navigation from '../components/Navigation';

import '../css/landing.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

// Slides Data
import slidesData from '../data/slides.json';
import Slideshow from '../components/Slideshow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import TripAdvisorIcon from '../components/TripAdvisorIcon';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import BannerImage from '../img/banner.JPG';
import { Helmet } from 'react-helmet';

const Landing = () => {
    return (
        <>
            <Helmet>
                <title>XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Explore the latest in water sports excitement in Protaras, Cyprus. From powerful Jet Skis to luxurious boats, and from thrilling Paragliding to smooth rides, we've got it all. Join us for an unforgettable experience on the beautiful waters of Protaras!" />

            </Helmet>

            <Navigation active="HOME"></Navigation>

            <main>
                <section className="banner-container">
                    <div className="banner">
                        <LazyLoadImage
                            className="banner-image"
                            effect="blur"
                            src={BannerImage} />

                        <div className="banner-darkener"></div>

                        <div className="social-media-banner">
                            <Link className="social-media-button" to="https://maps.app.goo.gl/xRQ3NpqTUND5fFFM8">
                                <FontAwesomeIcon icon={faGoogle} />
                            </Link>
                            <Link className="social-media-button" to="https://www.instagram.com/xswatersports">
                                <FontAwesomeIcon icon={faInstagram} />
                            </Link>
                            <Link className="social-media-button"
                                  to="https://www.tripadvisor.com/Attraction_Review-g667803-d6721783-Reviews-Xs_Watersports-Protaras_Paralimni_Famagusta_District.html">
                                <TripAdvisorIcon />
                            </Link>
                        </div>
                        {/* <img src={banner} alt="XS Banner"></img> */}
                        <div className="banner_text">
                            <div className="banner_text_left">
                                <h1>XS & STEPHANOS WATERSPORTS</h1>
                                <p>
                                    Explore the latest in water sports excitement in Protaras, Cyprus. From powerful Jet
                                    Skis to luxurious boats, and from thrilling Paragliding to smooth rides, we've got
                                    it all. Join us for an unforgettable experience on the beautiful waters of Protaras!
                                </p>

                                <div className="la_buttons-container">
                                    <div className="banner-link-container">
                                        <Link className="banner-buttons" to="tel:+357 99612229">
                                            <FontAwesomeIcon icon={faPhone} /> (+357) 99612229
                                        </Link>

                                        {/*<Link className="banner-buttons stephanos" to="tel:+357 99612229">*/}
                                        {/*    <FontAwesomeIcon icon={faPhone} /> +357 99637453*/}
                                        {/*</Link>*/}
                                    </div>

                                    <div className="banner-link-container">
                                        <Link className="banner-buttons" to="https://wa.me/35799612229" target="_blank">
                                            <FontAwesomeIcon icon={faWhatsapp} /> (+357) 99612229
                                        </Link>

                                        {/*<Link className="banner-buttons stephanos" to="https://wa.me/35799571486" target="_blank">*/}
                                        {/*    <FontAwesomeIcon icon={faWhatsapp} /> +357 99571486*/}
                                        {/*</Link>*/}
                                    </div>

                                    <div className="banner-link-container">
                                        <Link className="banner-buttons" to="mailto:xswatersports@cytanet.com.cy">
                                            <FontAwesomeIcon icon={faEnvelope} /> xswatersports@cytanet.com.cy
                                        </Link>

                                        {/*<Link className="banner-buttons stephanos" to="mailto:xswatersports@cytanet.com.cy">*/}
                                        {/*    <FontAwesomeIcon icon={faEnvelope} /> info@xswatersports.com*/}
                                        {/*</Link>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-container">
                    <div className="la_about_us">
                        <div className="au_left">
                            <h1>ABOUT US</h1>
                            <p>
                                XS Watersports began operation in 1980 as a
                                small family owned business in Protaras Cyprus,
                                and has emerged as an award winning watersports
                                operation. The qualified trainers are managed by
                                Stelios Stylianou, and Christos Hatzirakleous.
                                Due to Stelios’s and Christos’s commitment and
                                dedication to obtain the best international
                                watersports training and maintaining the latest
                                watersports equipment, the company has become
                                very successful.
                            </p>
                            <p>
                                Our aim is to provide excellent service with our
                                multilingual staff and make our customers
                                holiday in Cyprus, a memorable experience. Our
                                services offer something for EVERYBODY.
                            </p>

                            <Link className="link" to="/about">
                                READ MORE ABOUT US
                            </Link>
                        </div>

                        <div className="au_right">
                            {/*<div*/}
                            {/*    className="au_pic"*/}
                            {/*    style={{*/}
                            {/*        backgroundImage: `url(${parasailing})`*/}
                            {/*    }}*/}
                            {/*></div>*/}
                            <Slideshow slides={slidesData} />
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
};

export default Landing;
