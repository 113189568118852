import { Helmet } from 'react-helmet';

import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import OpeningHours from '../components/OpeningHours';

import '../css/about.css';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <>
            <Helmet>
                <title>ABOUT US | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Established in 1980 as a humble family-owned business in Protaras, Cyprus, XS Watersports has grown into an award-winning operation renowned for its exceptional services. Founded by Stelios Stylianou and Christos Hatzirakleous, our company has thrived under their commitment to obtaining the best international watersports training and maintaining cutting-edge equipment." />

            </Helmet>

            <Navigation active="ABOUT US"></Navigation>
            <div className="about_us">
                <div className="text_content">
                    <h2>About XS Watersports</h2>
                    <p>
                        Established in 1980 as a humble family-owned business in Protaras, Cyprus, XS Watersports has
                        grown into an award-winning operation renowned for its exceptional services. Founded by Stelios
                        Stylianou and Christos Hatzirakleous, our company has thrived under their commitment to
                        obtaining the best international watersports training and maintaining cutting-edge equipment.
                    </p>

                    <p>
                        Managed by Stelios and Christos, both passionate professionals in the field, XS Watersports is
                        dedicated to providing unforgettable experiences on the water. With a multilingual staff, our
                        aim is to ensure that every customer's holiday in Cyprus becomes a cherished memory.
                    </p>

                    <p>
                        At XS Watersports, we offer something for everyone. Whether you seek the thrill of parasailing
                        guided by qualified instructors from the British Hangliding and Paragliding Association (BHPA),
                        the excitement of jet skiing, or the tranquility of canoeing and stand-up paddleboarding (SUP),
                        we have the perfect activity for you. Our water ski and wakeboard sessions, led by certified
                        instructors from the British Water Ski and Wakeboard Federation (BWSW), guarantee both safety
                        and exhilaration.
                    </p>

                    <p>
                        Safety and customer satisfaction are paramount to us. Our experienced team, including
                        instructors from BHPA and BWSW, is committed to ensuring your enjoyment and security during
                        every visit. Equipped with top-quality gear and expert guidance, your adventure with us is bound
                        to be unforgettable.
                    </p>

                    <p>
                        We take pride in being recognized as the number 1 rated watersports provider in Cyprus, as rated
                        by Tripadvisor and Google Reviews.
                    </p>

                    <p>
                        Whether you're a seasoned water sports enthusiast or a first-time visitor seeking excitement, XS
                        Watersports invites you to discover luxury and thrill amidst the stunning waters of Protaras,
                        Cyprus.
                    </p>

                    <div className="awards">
                        <div id="TA_certificateOfExcellence635" className="TA_certificateOfExcellence">
                            <Link target="_blank"
                                  to="https://www.tripadvisor.com/Attraction_Review-g667803-d6721783-Reviews-Xs_Watersports-Protaras_Paralimni_Famagusta_District.html"><img
                                src="https://static.tacdn.com/img2/travelers_choice/widgets/tchotel_2023_L.png"
                                alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO" /></Link>
                        </div>

                        <iframe src="https://embedsocial.com/api/pro_hashtag/b0eea93eb1a9d3ebde9f9c73d31451639c2872a1"
                                height="200px" frameBorder="0" marginHeight="0" marginWidth="0"></iframe>


                    </div>


                </div>

                <div className="about_aside">
                    <iframe
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5495.47966664426!2d34.05484385221139!3d35.01592206305522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14dfc404b4ac2655%3A0x4ddb4f8d9ddfb6d7!2sXS%20Watersports!5e0!3m2!1sen!2s!4v1685207464382!5m2!1sen!2s"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>

                    <OpeningHours></OpeningHours>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default About;
