import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import '../css/activities.css';
import { Link, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

const Activities = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>ACTIVITIES | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="At XS Watersports, we offer a variety of fun activities for all ages. From exciting jet skiing and parasailing to relaxing paddleboarding and canoeing, there's something for everyone to enjoy. With expert instructors and stunning views in Protaras, Cyprus, XS Watersports guarantees a memorable experience on the water." />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="activities-container">
                <div className="card" onClick={(e) => navigate('parasailing')}>
                    <div className="card-image">
                        <LazyLoadImage
                            className="card-lazy-img"
                            alt="parasailing"
                            effect="blur"
                            src="/img_activities/parasailing/parasailing.jpg" />
                    </div>

                    <h2>Parasailing</h2>

                    <p>Glide above the stunning waters of Protaras and embrace the exhilarating freedom as you ascend.
                        Experience unforgettable moments and breathtaking views with us.</p>

                    <Link className="link" to="parasailing">
                        READ MORE
                    </Link>
                </div>

                <div className="card" onClick={(e) => navigate('speedboats')}>
                    <div className="card-image">
                        <LazyLoadImage
                            alt="speed boat"
                            effect="blur"
                            src="/img_activities/speedboat/luxury-boat-for-rent.jpg" />
                    </div>

                    <h2>SPEEDBOAT RENTAL</h2>

                    <p>Feel the thrill as you speed across the calm waters of Protaras. Enjoy an adrenaline-fueled
                        adventure and exhilarating moments on the serene surface with us.</p>

                    <Link className="link" to="speedboats">
                        READ MORE
                    </Link>
                </div>

                <div className="card" onClick={(e) => navigate('jetski')}>
                    <div className="card-image">
                        <LazyLoadImage
                            alt="jetski"
                            effect="blur"
                            src="/img_activities/jetski/jetski.jpg" />
                    </div>

                    <h2>JETSKI</h2>

                    <p>Feel the rush as you speed across the waves on our latest models, the fastest jet skis
                        available. Experience the adrenaline and exhilaration with us.</p>

                    <Link className="link" to="jetski">
                        READ MORE
                    </Link>
                </div>

                <div className="card" onClick={(e) => navigate('waterski')}>
                    <div className="card-image">
                        <LazyLoadImage
                            alt="waterski"
                            effect="blur"
                            src="/img_activities/waterski/waterski.jpg" />
                    </div>

                    <h2>WATERSKI</h2>

                    <p>Feel the exhilaration as you glide effortlessly across the water's surface. Whether you're a
                        beginner or an expert, our expert instructors will ensure an unforgettable experience.</p>

                    <Link className="link" to="waterski">
                        READ MORE
                    </Link>
                </div>

                <div className="card" onClick={(e) => navigate('wakeboard')}>
                    <div className="card-image">
                        <LazyLoadImage
                            alt="wakeboard"
                            effect="blur"
                            src="/img_activities/wakeboard/wakeboard.jpg" />
                    </div>

                    <h2>WAKEBOARD</h2>

                    <p>Experience the thrill of carving through the waves and catching big air on our wakeboards. With
                        expert guidance and top-of-the-line equipment, we'll make sure your ride is as exhilarating as
                        it gets.</p>

                    <Link className="link" to="wakeboard">
                        READ MORE
                    </Link>
                </div>

                <div className="card" onClick={(e) => navigate('lesson')}>
                    <div className="card-image">
                        <LazyLoadImage
                            alt="lesson"
                            effect="blur"
                            src="/img_activities/waterski/kids-learning-waterski.jpg" />
                    </div>

                    <h2>LESSON (WATERSKI & WAKEBOARD)</h2>

                    <p>Learn with ease using our innovative boom system, designed for stability and progression. Our
                        expert guides provide personalized instruction, ensuring you master waterskiing or wakeboarding
                        with confidence and excitement</p>

                    <Link className="link" to="lesson">
                        READ MORE
                    </Link>
                </div>


                <div className="card" onClick={(e) => navigate('towables')}>
                    <div className="card-image">
                        <LazyLoadImage
                            alt="towables"
                            effect="blur"
                            src="/img_activities/towables/watertoys.jpg" />
                    </div>

                    <h2>TOWABLES RIDES</h2>

                    <p>Dive into fun with our exciting range of water toys! From inflatable
                        rafts to floating loungers, we have everything you need to add a splash of excitement to your
                        day on the water.</p>

                    <Link className="link" to="towables">
                        READ MORE
                    </Link>
                </div>

                <div className="card" onClick={(e) => navigate('oxoon')}>
                    <div className="card-image">
                        <LazyLoadImage
                            alt="oxoon"
                            effect="blur"
                            src="/img_activities/oxoon/oxoon.jpg" />
                    </div>

                    <h2>HOVERCRAFT (OXOON)</h2>

                    <p>Experience the laid-back joy of gliding over crystal-clear waters with our 3-seater power-driven
                        Oxoons. Easy to operate, even for children (accompanied), it's a delightful family
                        experience.</p>

                    {/*<Link className="link" to="oxoon">*/}
                    {/*    READ MORE*/}
                    {/*</Link>*/}
                </div>


                <div className="card">
                    <div className="card-image">
                        <LazyLoadImage
                            alt="pedal boats"
                            effect="blur"
                            src="/img_activities/pedalos/10635952_865070190207189_4747440805190765353_n.jpg" />
                    </div>

                    <h2>PEDAL BOATS</h2>

                    <p>Enjoy a leisurely cruise on the serene waters with our pedal boats. Perfect for families and
                        couples, pedal your way through picturesque scenery and create unforgettable memories on the
                        water</p>

                    {/*<Link className="link" to="/">*/}
                    {/*    READ MORE*/}
                    {/*</Link>*/}
                </div>


                <div className="card">
                    <div className="card-image">
                        <LazyLoadImage
                            alt="kayaks"
                            effect="blur"
                            src="/img_activities/canoes/67767209_2403871569660369_6324270980365549568_n.jpg" />
                    </div>

                    <h2>KAYAKS</h2>

                    <p>Explore the beauty of the coastline at your own pace with our kayaks. Whether solo or tandem,
                        our kayaks offer an immersive way to experience the natural wonders of the sea.</p>

                    {/*<Link className="link" to="/">*/}
                    {/*    READ MORE*/}
                    {/*</Link>*/}
                </div>

                <div className="card">
                    <div className="card-image">
                        <LazyLoadImage
                            alt="sup"
                            effect="blur"
                            src="/img_activities/sup/stephanos-watersports-protaras-sup-03.jpg" />
                    </div>

                    <h2>STAND UP PADDLE</h2>

                    <p>Stand Up Paddle boarding (SUP): Embark on a serene adventure atop the shimmering waters. Whether
                        you're a beginner or a seasoned pro, our stand-up paddle boards offer a fun and engaging way to
                        explore the sea.</p>

                    {/*<Link className="link" to="/">*/}
                    {/*    READ MORE*/}
                    {/*</Link>*/}
                </div>
            </div>
            <Footer></Footer>
        </>);
};

export default Activities;