// Imported functions
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// CSS Styles
import './App.css';
import Landing from './views/Landing';
import About from './views/About';
import Activities from './views/Activities';
import Parasailing from './views/Activities/Parasailing';
import SpeedBoats from './views/Activities/SpeedBoats';
import Jetskis from './views/Activities/Jetskis';
import Waterski from './views/Activities/Waterski';
import Wakeboard from './views/Activities/Wakeboard';
import Lesson from './views/Activities/Lesson';
import Oxoon from './views/Activities/Oxoon';
import Towables from './views/Activities/Towables';
import ImagesAndVideos from './views/ImagesAndVideos';
import Contact from './views/Contact';
import ScrollToTop from './context/ScrollToTop';
import React from 'react';
import Map from './views/Map';
import ErrorPage from './views/ErrorPage';
import Reviews from './views/Reviews';

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Landing />}></Route>

                <Route path="/about" element={<About />}></Route>

                <Route path="/activities" element={<Activities />}></Route>
                <Route path="/activities/parasailing" element={<Parasailing />} />
                <Route path="/activities/speedboats" element={<SpeedBoats />} />
                <Route path="/activities/jetski" element={<Jetskis />} />
                <Route path="/activities/waterski" element={<Waterski />} />
                <Route path="/activities/wakeboard" element={<Wakeboard />} />
                <Route path="/activities/lesson" element={<Lesson />} />
                <Route path="/activities/oxoon" element={<Oxoon />} />
                <Route path="/activities/towables" element={<Towables />} />

                <Route path="/activities/prices" element={<Activities />}></Route>
                <Route path="/activities/prices/parasailing" element={<Parasailing prices={true} />} />
                <Route path="/activities/prices/speedboats" element={<SpeedBoats prices={true} />} />
                <Route path="/activities/prices/jetski" element={<Jetskis prices={true} />} />
                <Route path="/activities/prices/waterski" element={<Waterski prices={true} />} />
                <Route path="/activities/prices/wakeboard" element={<Wakeboard prices={true} />} />
                <Route path="/activities/prices/lesson" element={<Lesson prices={true} />} />
                <Route path="/activities/prices/oxoon" element={<Oxoon prices={true} />} />
                <Route path="/activities/prices/towables" element={<Towables prices={true} />} />

                <Route path="/reviews" element={<Reviews />} />

                <Route path="/photos" element={<ImagesAndVideos />} />

                <Route path="/contact" element={<Contact />} />

                <Route path="/map" element={<Map />} />

                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
