import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

import waterskiData from '../../data/waterski.json';
import Gallery from '../../components/Gallery';
import { Helmet } from 'react-helmet';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Waterski = ({ prices = false }) => {
    return (
        <>
            <Helmet>
                <title>WATERSKI | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Feel the exhilaration as you glide effortlessly across the water's surface. Whether you're a beginner or an expert, our expert instructors will ensure an unforgettable experience." />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">
                    <h2>WATERSKI</h2>

                    <p>There is nobody like our gold award winner (from British Waterski Association) instructor Stelios
                        Stylianou for whom the words, “I can’t” and “It’s not possible” do not exist. Stelios has
                        trained individuals from the age of 4 up to 70 years old to ski like professionals. Stelios uses
                        his many years of teaching experience to tailor each individual lesson to the student’s ability
                        and experience. Equipped with the latest training booms for beginners we ensure you to obtain
                        the correct body position and confidence in no time. Our aim is to find the most enjoyable way
                        to achieve your personal goals either as a beginner or an experienced skier.. All of our
                        instructors are qualified members of the British Waterski Federation trained to provide you with
                        all the information you require to for a fun and enjoyable ski.</p>

                    {prices ? (
                        <>
                            <div className="pricelist">
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> 5km / 15 minutes : <span className='price'>€30</span></span>
                            </div>
                        </>
                    ) : null}

                    <Gallery data={waterskiData} className="grid" />
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Waterski;
