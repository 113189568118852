import { Link } from 'react-router-dom';
import '../css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faInstagram } from '@fortawesome/free-brands-svg-icons';
import TripAdvisorIcon from './TripAdvisorIcon';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <div>
            <div className="footer">
                <div className="footer_container">
                    <div className="footer_row">
                        <h5>INFORMATIONS</h5>
                        <Link className="link" to="/about">About Us</Link>
                        <Link className="link" to="/activities">Activities</Link>
                        <Link className="link" to="/photos">Photos & Videos</Link>
                        <Link className="link" to="/contact">Contact</Link>
                        <Link className="link" to="https://www.stephanoswatersports.com/" target='_blank'>Stephanos Watersports</Link>
                    </div>

                    <div className="footer_row">
                        <h5>CONTACT US</h5>
                        <Link
                            className="link n-underline"
                            target="_blank"
                            to="https://maps.app.goo.gl/xRQ3NpqTUND5fFFM8"
                        >
                            <FontAwesomeIcon icon={faLocationDot} /> Sunrise Beach, Protaras Cyprus
                        </Link>
                        <Link className="link n-underline" to="tel:0035799612229">
                            <FontAwesomeIcon icon={faPhone} /> +357 99612229 (Christos)
                        </Link>

                        <Link className="link n-underline" to="tel:0035799567375">
                            <FontAwesomeIcon icon={faPhone} /> +357 99567375 (Kyriakos)
                        </Link>

                        <Link className="link n-underline" to="mailto:xswatersports@cytanet.com.cy">
                            <FontAwesomeIcon icon={faEnvelope} /> xswatersports@cytanet.com.cy
                        </Link>

                        <Link className="link n-underline" to="mailto:stephanoswatersports@gmail.com">
                            <FontAwesomeIcon icon={faEnvelope} /> stephanoswatersports@gmail.com
                        </Link>
                    </div>

                    <div className="footer_row">
                        <h5>SOCIAL NETWORKS</h5>
                        <div className="f_social">
                            <Link className="link n-underline" to="https://maps.app.goo.gl/xRQ3NpqTUND5fFFM8"
                                  target="_blank">
                                <FontAwesomeIcon icon={faGoogle} />
                            </Link>
                            <Link className="link n-underline" to="https://www.instagram.com/xswatersports/"
                                  target="_blank">
                                <FontAwesomeIcon icon={faInstagram} />
                            </Link>
                            <Link className="link n-underline"
                                  to="https://www.tripadvisor.com/Attraction_Review-g667803-d6721783-Reviews-Xs_Watersports-Protaras_Paralimni_Famagusta_District.html"
                                  target="_blank">
                                <TripAdvisorIcon fill={'var(--dark-text-color)'} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="f_copyright">
                <h4><Link className="link c-normal" to="/">XS & STEPHANOS WATERSPORTS</Link> © {new Date().getFullYear()}. ALL
                    RIGHTS RESERVED.</h4>
            </div>
        </div>
    );
};

export default Footer;
