import Navigation from '../components/Navigation';

import '../css/landing.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import '../css/errorPage.css';
import BannerImage from '../img/banner.JPG';
import { Helmet } from 'react-helmet';

const ErrorPage = () => {
    return (
        <>
            <Helmet>
                <title>404 | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Explore the latest in water sports excitement in Protaras, Cyprus. From powerful Jet Skis to luxurious boats, and from thrilling Paragliding to smooth rides, we've got it all. Join us for an unforgettable experience on the beautiful waters of Protaras!" />
            </Helmet>
            <Navigation active="HOME"></Navigation>
            <main className="error-page-main">
                <LazyLoadImage className="banner-image" effect="blur" src={BannerImage} />

                <div className="error-page-container">
                    <div className='error-page-code'>404</div>

                    <p>Page you are looking for is not here.</p>

                    <Link to='/'>GO HOME</Link>
                </div>
            </main>


            <Footer />
        </>
    );
};

export default ErrorPage;
