import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import React from 'react';

import parasailingData from '../../data/parasailing.json';
import Gallery from '../../components/Gallery';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Parasailing = ({ prices = false }) => {
    return (
        <>
            <Helmet>
                <title>Parasailing | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Glide above the stunning waters of Protaras and embrace the exhilarating freedom as you ascend. Experience unforgettable moments and breathtaking views with us." />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us">
                <div className="text_content">
                    <h2>Parasailing</h2>

                    <p>XS Parasailing provides you with the most breathtaking views of Sunrise Beach and Fig Tree Bay.
                        Your parasailing trip will take you solo, tandem or triple up to 600 ft above the water, an
                        exhilarating flying experience. Our latest colourful parachutes are winched into the air from
                        our Warrior 33 Parasail Boat where landings and takes off couldn’t be more gentle or secure. The
                        Warrior 33 can carry up to 14 people so you and your friends can enjoy your experience flying.

                        Our qualified instructors from BHPA ( British Hangliding and Paragliding Association) will
                        assist you with a full pre-flight briefing. We fly more than 3000 customers every season from
                        all age ranges. Every flyer receives a certificate with the option of a photograph.</p>

                    {prices ? (
                          <>
                              <div className="pricelist">
                                  <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Single: <span className='price'>€50</span></span>
                                  <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Double: <span className='price'>€40 per person</span></span>
                                  <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Triple: <span className='price'>€35 per person</span> </span>
                              </div>
                          </>
                    ): null}


                    <iframe className="youtube-video"
                            src="https://www.youtube.com/embed/a46tNPlV_8o?si=xgK5aFU9Unk3u5um"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </div>

                <div className="about_aside">
                    <Gallery data={parasailingData} />
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Parasailing;
