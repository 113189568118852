import React, { useState, useEffect } from 'react';
import MapComponent from '../components/MapComponent';

import 'leaflet/dist/leaflet.css';
import { Helmet } from 'react-helmet';

function Map() {
    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }, []);

    return (
        <div>
            <Helmet>
                <title>MAP | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Explore the latest in water sports excitement in Protaras, Cyprus. From powerful Jet Skis to luxurious boats, and from thrilling Paragliding to smooth rides, we've got it all. Join us for an unforgettable experience on the beautiful waters of Protaras!" />

            </Helmet>

            {currentLocation && (
                <MapComponent
                    center={[currentLocation.lat, currentLocation.lng]}
                    zoom={13}
                    markerPosition={[currentLocation.lat, currentLocation.lng]}
                />
            )}
        </div>
    );
}

export default Map;
