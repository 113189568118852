import React, { useState } from 'react';
import '../css/chatIcons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import CSS file for styling

const ChatIcons = () => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="chat-icons-container">
            {expanded && (
                <>
                    <div className="chat-icon">
                        <Link className='link' to='tel:0035799612229'><FontAwesomeIcon className='icon' icon={faPhone} /></Link>
                        <div className="tooltip"></div>
                    </div>
                    <div className="chat-icon">
                        <Link to="https://wa.link/apvsvx" target='_blank' className='link'><FontAwesomeIcon className='icon' icon={faWhatsapp} /></Link>
                        <div className="tooltip"></div>
                    </div>
                    <div className="chat-icon">
                        <Link to={'mailto:xswatersports@cytanet.com.cy'} className="link"><FontAwesomeIcon className='icon' icon={faEnvelope} /></Link>
                        <div className="tooltip"></div>
                    </div>
                </>
            )}

            <div className={`chat-icon main-icon ${expanded ? 'expanded' : ''}`} onClick={toggleExpanded}>
                {expanded ? (
                    <FontAwesomeIcon className='icon' icon={faTimes} />
                ): (
                    <FontAwesomeIcon className='icon' icon={faPhone} />
                )}
                <div className={`tooltip ${expanded ? 'tooltip-expanded' : ''}`}></div>
            </div>

        </div>
    );
};

export default ChatIcons;
