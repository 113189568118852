import Navigation from '../../components/Navigation';
import Slideshow from '../../components/Slideshow';
import Footer from '../../components/Footer';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

// Data
import jaroma1Slides from '../../data/jaroma1.json';
import fourWinns from '../../data/fourWinns.json';
import campionRed from '../../data/campionRed.json';
import campionBlue from '../../data/campionBlue.json';
import searayRed from '../../data/searayRed.json';
import searayBlack from '../../data/searayBlack.json';
import fourWinnsLuxury from '../../data/fourWinnsLuxury.json';
import { Helmet } from 'react-helmet';

const SpeedBoats = ({ prices = false }) => {
    return (
        <>
            <Helmet>
                <title>Speed Boats Rental | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Go for a romantic cruise on the Mediterranean Sea or a relaxing family picnic under the wonderful blue sky and over the crystal clear waters" />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">
                    <h2>Speedboat Rental</h2>

                    <p>Go for a romantic cruise on the Mediterranean Sea or a relaxing family picnic under the wonderful
                        blue sky and over the crystal clear waters. Search for a secluded spot where you can anchor and
                        enjoy with your friends your own private swimming area. You can choose either half hour or an
                        hour rental periods to accommodate your time and budget. Longer rentals are available and if you
                        prefer a driver can be provided.</p>

                    <div className="boats-row">
                        <div className="boat-card">
                            <div className="card-image">
                                <Slideshow slides={jaroma1Slides} />
                            </div>

                            <h2>JAROMA</h2>

                            <div className="boat-details">
                                <span className="boat-details-item">5 PERSONS</span>
                                <span className="boat-details-item">90HP OUTBOARD</span>
                                <span className="boat-details-item">LENGTH 16FT</span>
                            </div>


                            {prices ? (
                                <>
                                    <div className="pricelist">
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 30 minutes: <span className='price'>€60</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 60 minutes: <span className='price'>€80</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> Contact for longer rental</span>
                                    </div>
                                </>
                            ) : null}

                            <div className="boat-features">
                                <h3>The boat features:</h3>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Bimini top</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Ladder</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Music system</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Sun bench</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Cool box</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> VHF</span>
                            </div>

                        </div>

                        <div className="boat-card">
                            <div className="card-image">
                                <Slideshow slides={fourWinns} />
                            </div>

                            <h2>FOUR WINNS</h2>

                            <div className="boat-details">
                                <span className="boat-details-item">6 PERSONS</span>
                                <span className="boat-details-item">100HP OUTBOARD</span>
                                <span className="boat-details-item">LENGTH 18FT</span>
                            </div>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 30 minutes: <span className='price'>€70</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 60 minutes: <span className='price'>€100</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> Contact for longer rental</span>
                                    </div>
                                </>
                            ) : null}

                            <div className="boat-features">
                                <h3>The boat features:</h3>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Bimini top</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Ladder</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Music system</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Sun bench</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Cool box</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> VHF</span>
                            </div>
                        </div>

                        <div className="boat-card">
                            <div className="card-image">
                                <Slideshow slides={campionRed} />
                            </div>

                            <h2>CAMPION BOW RIDER</h2>

                            <div className="boat-details">
                                <span className="boat-details-item">7 PERSONS</span>
                                <span className="boat-details-item">130HP OUTBOARD</span>
                                <span className="boat-details-item">LENGTH 19FT</span>
                            </div>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 30 minutes: <span className='price'>€80</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 60 minutes: <span className='price'>€130</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> Contact for longer rental</span>
                                    </div>
                                </>
                            ) : null}

                            <div className="boat-features">
                                <h3>The boat features:</h3>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Bimini top</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Ladder</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Music system</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Sun bench</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Cool box</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> VHF</span>
                            </div>
                        </div>

                        <div className="boat-card">
                            <div className="card-image">
                                <Slideshow slides={campionBlue} />
                            </div>

                            <h2>CAMPION BOW RIDER</h2>

                            <div className="boat-details">
                                <span className="boat-details-item">7 PERSONS</span>
                                <span className="boat-details-item">150HP OUTBOARD</span>
                                <span className="boat-details-item">LENGTH 19FT</span>
                            </div>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 30 minutes: <span className='price'>€90</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 60 minutes: <span className='price'>€140</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> Contact for longer rental</span>
                                    </div>
                                </>
                            ) : null}

                            <div className="boat-features">
                                <h3>The boat features:</h3>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Bimini top</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Ladder</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Music system</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Sun bench</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Cool box</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> VHF</span>
                            </div>
                        </div>

                        <div className="boat-card">
                            <div className="card-image">
                                <Slideshow slides={searayRed} />
                            </div>

                            <h2>SEA RAY 190 SPORT</h2>

                            <div className="boat-details">
                                <span className="boat-details-item">7 PERSONS</span>
                                <span className="boat-details-item">230HP OUTBOARD</span>
                                <span className="boat-details-item">LENGTH 20FT</span>
                            </div>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 30 minutes: <span className='price'>€100</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 60 minutes: <span className='price'>€150</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> Contact for longer rental</span>
                                    </div>
                                </>
                            ) : null}

                            <div className="boat-features">
                                <h3>The boat features:</h3>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Bimini top</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Luxury seats</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Swimming Platform</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Ladder</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> High Quality Music system</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Sun bench</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Cool box</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> VHF</span>
                            </div>
                        </div>

                        <div className="boat-card">
                            <div className="card-image">
                                <Slideshow slides={searayBlack} />
                            </div>

                            <h2>SEA RAY 205 LUXURY BOAT</h2>

                            <div className="boat-details">
                                <span className="boat-details-item">7 PERSONS</span>
                                <span className="boat-details-item">250HP OUTBOARD</span>
                                <span className="boat-details-item">LENGTH 21FT</span>
                            </div>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 30 minutes: <span className='price'>€120</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 60 minutes: <span className='price'>€180</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> Contact for longer rental</span>
                                    </div>
                                </>
                            ) : null}

                            <div className="boat-features">
                                <h3>The boat features:</h3>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Bimini top</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Luxury seats</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Swimming Platform</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Ladder</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> High Quality Music system</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Sun bench</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Cool box</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Shower</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> VHF</span>
                            </div>
                        </div>

                        <div className="boat-card">
                            <div className="card-image">
                                <Slideshow slides={fourWinnsLuxury} />
                            </div>

                            <h2>FOUR WINNS LUXURY BOAT</h2>

                            <div className="boat-details">
                                <span className="boat-details-item">9 PERSONS</span>
                                <span className="boat-details-item">250HP OUTBOARD</span>
                                <span className="boat-details-item">LENGTH 22FT</span>
                            </div>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 30 minutes: <span className='price'>€150</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> 60 minutes: <span className='price'>€250</span></span>
                                        <span><FontAwesomeIcon className="icon"
                                                               icon={faAngleRight} /> Contact for longer rental</span>
                                    </div>
                                </>
                            ) : null}

                            <div className="boat-features">
                                <h3>The boat features:</h3>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Bimini top</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Luxury seats</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Swimming Platform</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Ladder</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> High Quality Music system</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Sun bench</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Cool box</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> Shower</span>
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> VHF</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default SpeedBoats;