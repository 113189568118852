import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

import wakeboardData from '../../data/wakeboard.json';
import Gallery from '../../components/Gallery';
import { Helmet } from 'react-helmet';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Wakeboard = ({prices = false}) => {
    return (
        <>
            <Helmet>
                <title>WAKEBOARD | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description" content="Experience the thrill of carving through the waves and catching big air on our wakeboards. With expert guidance and top-of-the-line equipment, we'll make sure your ride is as exhilarating as it gets." />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">
                    <h2>WAKEBOARD</h2>

                    <p>There is nobody like our gold award winner (from British Waterski Association) instructor Stelios
                        Stylianou for whom the words, “I can’t” and “It’s not possible” do not exist. Stelios has
                        trained individuals from the age of 4 up to 70 years old to ski like professionals. Stelios uses
                        his many years of teaching experience to tailor each individual lesson to the student’s ability
                        and experience. Equipped with the latest training booms for beginners we ensure you to obtain
                        the correct body position and confidence in no time. Our aim is to find the most enjoyable way
                        to achieve your personal goals either as a beginner or an experienced skier.. All of our
                        instructors are qualified members of the British Waterski Federation trained to provide you with
                        all the information you require to for a fun and enjoyable ski.</p>

                    <p>XS Watersports offers equipment for the experienced or inexperienced wakeboarders, select the
                        wakeboard that suits your ability from our range of wakeboards. Our boats are equipped with
                        towers and tow ropes to improve and develop your wakeboarding performance. Backside Butterlide,
                        body slide, Perez, Powerslide are few tricks that our students learn to perform with unhurried
                        coaching. Share your tricks with us and be part of the XS Watersports Club.
                    </p>

                    {prices ? (
                        <>
                            <div className="pricelist">
                                <span><FontAwesomeIcon className="icon" icon={faAngleRight} /> 5km / 15 minutes : <span className='price'>€35</span></span>
                            </div>
                        </>
                    ) : null}


                    <Gallery data={wakeboardData} className="grid" />
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Wakeboard;
