import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import Slideshow from '../../components/Slideshow';

// DATA
import ufoData from '../../data/ufo.json';
import stingrayData from '../../data/stingray.json';
import ringoData from '../../data/ringo.json';
import shrekData from '../../data/shrek.json';
import twisterData from '../../data/twister.json';
import bananaData from '../../data/banana.json';
import { Helmet } from 'react-helmet';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Towables = ({ prices = false }) => {
    return (
        <>
            <Helmet>
                <title>Towables Rides | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Dive into fun with our exciting range of water toys! From inflatable rafts to floating loungers, we have everything you need to add a splash of excitement to your day on the water." />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">

                    <div className="side-card">
                        <div className="card-text">
                            <h2>Crazy UFO (SOFA)</h2>

                            <p>For those seeking an adrenaline-packed adventure, hop aboard our 8-seater sofa toy! Get
                                ready
                                for an exhilarating ride that's bound to have everyone laughing and screaming with
                                excitement.
                                With plenty of space for your friends and family, it's the perfect way to make
                                unforgettable
                                memories on the water.</p>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> <span className='price'>€15 per person</span></span>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <Slideshow slides={ufoData} className="contain" auto={false} />
                    </div>

                    <div className="side-card">
                        <div className="card-text">
                            <h2>Stingray</h2>

                            <p>This inflatable mattress is the latest craze in water sports excitement. Effortlessly
                                gliding across the water with speed and agility, it's a favorite among thrill-seekers
                                and extreme sports enthusiasts alike.</p>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> <span className='price'>€15 per person</span></span>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <Slideshow slides={stingrayData} className="contain" auto={false} />
                    </div>

                    <div className="side-card">
                        <div className="card-text">
                            <h2>Ringos (Tubes)</h2>

                            <p>A must for extreme sports enthusiasts. Hold on tight as you challenge your friends and
                                teeter on the edge of excitement. Stay on the rings if you can, and dare to push your
                                limits!</p>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> <span className='price'>€15 per person</span></span>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <Slideshow slides={ringoData} className="contain" auto={false} />
                    </div>

                    <div className="side-card">
                        <div className="card-text">
                            <h2>Aqua R3 (Shrek)</h2>

                            <p>A thrilling combination of a sofa-style ride and the
                                extreme excitement of Ringos, with an extra bounce! Hold on tight as you and your
                                friends challenge the waves and enjoy the excitement. It's simple, it's fun, and it's an
                                adventure you won't forget!</p>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> <span className='price'>€15 per person</span></span>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <Slideshow slides={shrekData} className="contain" auto={false} />
                    </div>

                    <div className="side-card">
                        <div className="card-text">
                            <h2>Twister</h2>

                            <p>An exciting water ride that twists and turns, perfect for those seeking an exhilarating
                                experience! Hold on tight as you spin and twirl through the waves, challenging your
                                friends to see who can hang on the longest. And don't worry - it's guaranteed to bring
                                laughs, not queasiness! Get ready for an adventure that will leave you smiling from ear
                                to ear!</p>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> <span className='price'>€15 per person</span></span>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <Slideshow slides={twisterData} className="contain" auto={false} />
                    </div>

                    <div className="side-card">
                        <div className="card-text">
                            <h2>Banana</h2>

                            <p>Want to take the stress out of life? Laugh and scream like a little child. Try our banana
                                ride and enjoy the waves! There is no way back, the ride will finish when our driver is
                                ensured that you really had enough…fun. It’s the best way to spend time with family and
                                friends as our bananas can accommodate from 2 to 8 people. Any ages are
                                welcome.</p>

                            {prices ? (
                                <>
                                    <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> <span className='price'>€15 per person</span></span>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <Slideshow slides={bananaData} className="contain" auto={false} />
                    </div>

                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Towables;