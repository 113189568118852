import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

import '../css/about.css';

// Photos
import photos from '../data/photos.json';
import Gallery from '../components/Gallery';
import { Helmet } from 'react-helmet';

const ImagesAndVideos = () => {
    return (
        <>
            <Helmet>
                <title>PHOTOS & VIDEOS | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Explore the latest in water sports excitement in Protaras, Cyprus. From powerful Jet Skis to luxurious boats, and from thrilling Paragliding to smooth rides, we've got it all. Join us for an unforgettable experience on the beautiful waters of Protaras!" />

            </Helmet>

            <Navigation active="PHOTOS & VIDEOS"></Navigation>
            <div className="about_us">
                <div className="text_content">
                    <h2>Photos</h2>

                    <Gallery data={photos} className="grid" />
                </div>

                <div className="about_aside">
                <h2>Videos</h2>
                    <iframe className="yt-video" src="https://www.youtube.com/embed/0PEHae9caCQ?si=8AoERnwfL7wZeZTv"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>

                    <iframe className="yt-video" src="https://www.youtube.com/embed/a46tNPlV_8o?si=zyEkuHYW0Z4NjtO7"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default ImagesAndVideos;
