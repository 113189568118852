import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import OpeningHours from '../components/OpeningHours';

import '../css/about.css';
import { Helmet } from 'react-helmet';

const Reviews = () => {
    return (
        <>
            <Helmet>
                <title>REVIEWS | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="We take pride in being recognized as the number 1 rated watersports provider in Cyprus, as rated by Tripadvisor and Google Reviews" />
            </Helmet>

            <Navigation active="REVIEWS"></Navigation>
            <div className="reviews_container">
                <div className="elfsight-app-3035d5ce-13d3-446a-b78c-74290ff03bfc" data-elfsight-app-lazy></div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Reviews;
