import React from 'react';

import '../css/openingHours.css';

const OpeningHours = () => {
    // const days = [
    //     { name: 'Monday', hours: '8:00 AM - Sunset' },
    //     { name: 'Tuesday', hours: '8:00 AM - Sunset' },
    //     { name: 'Wednesday', hours: '8:00 AM - Sunset' },
    //     { name: 'Thursday', hours: '8:00 AM - Sunset' },
    //     { name: 'Friday', hours: '8:00 AM - Sunset' },
    //     { name: 'Saturday', hours: '8:00 AM - Sunset' },
    //     { name: 'Sunday', hours: '8:00 AM - Sunset' }
    // ];
    //
    // const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });

    return (
        <div className="opening_hours">
            <h2>Opening Hours</h2>
            {/*{days.map((day) => (*/}
            {/*  <div key={day.name} className={day.name === currentDay ? 'date current-day' : 'date'}>*/}
            {/*    <div>{day.name}</div>*/}
            {/*    <div>{day.hours}</div>*/}
            {/*  </div>*/}
            {/*))}*/}

            <div className="date current-day">
                <div>MON - SUN</div>
                <div>8:00 - Sunset</div>
            </div>
        </div>
    );
};

export default OpeningHours;
