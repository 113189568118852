import React from 'react';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';

import DriveArea from '../data/map/DriveArea.json';

function MapComponent({ center, zoom, markerPosition }) {
    const PolygonCoordinates = DriveArea.map(cord => [cord[1], cord[0]])



    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <MapContainer center={[35.1264, 33.4299]} zoom={9} style={{ height: '100%', width: '100%' }}>
                {/* Use the satellite imagery tile layer */}
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />


                {/* Draw a polygon on the map */}
                <Polygon positions={PolygonCoordinates} color="blue" />
            </MapContainer>
        </div>
    );
}

export default MapComponent;
