import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

import oxoonData from '../../data/oxoon.json';
import Gallery from '../../components/Gallery';
import { Helmet } from 'react-helmet';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Oxoon = ({ prices = false }) => {
    return (
        <>
            <Helmet>
                <title>HOVERCRAFT (OXOON) | XS & Stephanos Watersports – Protaras Cyprus</title>
                <meta name="description"
                      content="Experience the laid-back joy of gliding over crystal-clear waters with our 3-seater power-driven Oxoons. Easy to operate, even for children (accompanied), it's a delightful family experience." />
            </Helmet>

            <Navigation active="ACTIVITIES"></Navigation>
            <div className="about_us w-100">
                <div className="text_content">
                    <h2>Hovercraft (OXOON)</h2>

                    <p>Experience the laid-back joy of gliding over crystal-clear waters with our 3-seater power-driven
                        Oxoons. Easy to operate, even for children (accompanied), it's a delightful family
                        experience.</p>

                    {prices ? (
                        <>
                            <div className="pricelist">
                                <span><FontAwesomeIcon className="icon"
                                                       icon={faAngleRight} /> 30 minutes : <span className='price'>€30</span></span>
                            </div>
                        </>
                    ) : null}

                    <Gallery data={oxoonData} className="grid" />
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Oxoon;
