import { useState, useRef } from 'react';
import '../css/navigation.css';
import { Link, useNavigate } from 'react-router-dom';
import ChatIcons from './ChatIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navigation = ({ active }) => {
    const [isSticky] = useState(false);
    const ref = useRef(null);

    const navigate = useNavigate();

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollPosition = window.scrollY;
    //         setIsSticky(scrollPosition > 0);
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     // Cleanup the event listener when the component is unmounted
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    function handleMenuClick() {
        ref.current.classList.toggle('active');
    }

    return (
        <nav className={isSticky ? 'sticky' : ''}>
            <div className="navigation_container">
                <div className="navigation_logo" onClick={(e) => navigate('/')}></div>

                <div className="navigation_links">
                    <Link to="/"
                          className={`link-item ${
                              active == 'HOME' ? 'active' : ''
                          }`}
                          data-link="HOME"
                    >
                        HOME
                    </Link>
                    <Link to="/about"
                          className={`link-item ${
                              active == 'ABOUT US' ? 'active' : ''
                          }`}
                          data-link="HOME"
                    >
                        ABOUT US
                    </Link>
                    <Link
                        to="/activities"
                        className={`link-item ${
                            active == 'ACTIVITIES' ? 'active' : ''
                        }`}
                        data-link="HOME"
                        data-dropdown="true"
                    >
                        ACTIVITIES
                    </Link>

                    {/*<Link*/}
                    {/*    to="/reviews"*/}
                    {/*    className={`link-item ${*/}
                    {/*        active == 'REVIEWS' ? 'active' : ''*/}
                    {/*    }`}*/}
                    {/*    data-link="HOME"*/}
                    {/*    data-dropdown="true"*/}
                    {/*>*/}
                    {/*    REVIEWS*/}
                    {/*</Link>*/}

                    <Link
                        to="/photos"
                        className={`link-item ${
                            active == 'PHOTOS & VIDEOS' ? 'active' : ''
                        }`}
                        data-link="HOME"
                    >
                        PHOTOS & VIDEOS
                    </Link>
                    <Link
                        to="/contact"
                        className={`link-item ${
                            active == 'CONTACT' ? 'active' : ''
                        }`}
                        data-link="HOME"
                    >
                        CONTACT
                    </Link>
                </div>

                <div className="menu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faBars} /> MENU
                </div>


            </div>

            <div className="mobile_links" ref={ref}>
                <Link to="/"
                      className={`link-item ${
                          active == 'HOME' ? 'active' : ''
                      }`}
                      data-link="HOME"
                >
                    HOME
                </Link>
                <Link to="/about"
                      className={`link-item ${
                          active == 'ABOUT US' ? 'active' : ''
                      }`}
                      data-link="HOME"
                >
                    ABOUT US
                </Link>
                <Link
                    to="/activities"
                    className={`link-item ${
                        active == 'ACTIVITIES' ? 'active' : ''
                    }`}
                    data-link="HOME"
                    data-dropdown="true"
                >
                    ACTIVITIES
                </Link>
                {/*<Link*/}
                {/*    to="/reviews"*/}
                {/*    className={`link-item ${*/}
                {/*        active == 'REVIEWS' ? 'active' : ''*/}
                {/*    }`}*/}
                {/*    data-link="HOME"*/}
                {/*    data-dropdown="true"*/}
                {/*>*/}
                {/*    REVIEWS*/}
                {/*</Link>*/}
                <Link
                    to="/photos"
                    className={`link-item ${
                        active == 'PHOTOS & VIDEOS' ? 'active' : ''
                    }`}
                    data-link="HOME"
                >
                    PHOTOS & VIDEOS
                </Link>
                <Link
                    to="/contact"
                    className={`link-item ${
                        active == 'CONTACT' ? 'active' : ''
                    }`}
                    data-link="HOME"
                >
                    CONTACT
                </Link>
            </div>

            <ChatIcons />
        </nav>
    );
};

// Export Navigation function
export default Navigation;
