import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// CSS
import '../css/slideshow.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Slideshow = ({ slides, autoSlideInterval = 5000, className, auto = true }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);

    const containerRef = useRef(null);
    const modalImageRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isPaused && auto) {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            }
        }, autoSlideInterval);

        return () => clearInterval(intervalId);
    }, [autoSlideInterval, isPaused, slides.length]);

    const nextSlide = () => {
        setModalImageIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const prevSlide = () => {
        setModalImageIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    const openModal = (index) => {
        setModalImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [startX, setStartX] = useState(null);
    const [isSwiping, setIsSwiping] = useState(false);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setIsSwiping(true);
    };

    const handleTouchMove = (e) => {
        if (!isSwiping) return;

        const currentX = e.touches[0].clientX;
        const deltaX = currentX - startX;

        if (deltaX > 100) { // You can adjust this threshold according to your preference
            // Trigger your leftward swipe action here
            setIsSwiping(false); // Reset swipe state
            setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
            prevSlide();
        } else if (deltaX < -50) {
            setIsSwiping(false);
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            nextSlide();
        }
    };

    const handleTouchEnd = () => {
        setIsSwiping(false); // Reset swipe state
    };

    useEffect(() => {
        const handleResize = () => {
            if (className == 'w-100') {
                if (containerRef.current) {
                    const containerWidth = containerRef.current.offsetWidth;
                    const aspectRatioHeight = (containerWidth * 2) / 3;
                    containerRef.current.style.height = `${aspectRatioHeight}px`;
                }
            }
        };

        handleResize(); // Initial resize

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className={`slideshow ${className}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
             ref={containerRef}>
            <button className="previous-button-slideshow"
                    onClick={() => setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length)}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <div className="slide-container">
                {slides.map((slide, index) => (
                    <div key={index} className="slide"
                         style={{ transform: `translateX(${(index - currentSlide) * 100}%)` }}
                         onClick={() => openModal(index)}>
                        <img
                            alt={slide.altText}
                            src={slide.imageUrl}
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} />
                    </div>
                ))}
            </div>
            <button className="next-button-slideshow"
                    onClick={() => setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)}>
                <FontAwesomeIcon icon={faAngleRight} />
            </button>
            {isModalOpen && (
                <div className="modal" onClick={closeModal}>
                    <span className="close">&times;</span>
                    <div className="modal-content">
                        <button className="previous-button-modal" onClick={(e) => e.stopPropagation() || prevSlide()}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        {/*<img src={slides[modalImageIndex].imageUrl} alt={slides[modalImageIndex].altText}*/}
                        {/*     onClick={(e) => e.stopPropagation()} onTouchStart={handleTouchStart}*/}
                        {/*     onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} />*/}

                        <TransformWrapper>
                            <TransformComponent>
                                <img
                                    ref={modalImageRef}
                                    src={slides[modalImageIndex].imageUrl}
                                    alt={slides[modalImageIndex].altText}
                                    onClick={(e) => e.stopPropagation()}
                                    onTouchStart={handleTouchStart}
                                    onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} />
                                />
                            </TransformComponent>
                        </TransformWrapper>
                        <button className="next-button-modal" onClick={(e) => e.stopPropagation() || nextSlide()}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Slideshow;
