import React, { useState } from 'react';
import '../css/gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Gallery = ({ data, className }) => {
    // Calculate the number of columns based on viewport width
    const calculateNumColumns = () => {
        if (window.innerWidth >= 1300) {
            return Math.ceil(data.length / 3);
        } else if (window.innerWidth >= 850) {
            return Math.ceil(data.length / 2);
        } else {
            return data.length;
        }
    };

    // Update the number of columns when the window is resized
    const [numColumns, setNumColumns] = useState(calculateNumColumns());
    React.useEffect(() => {
        const handleResize = () => {
            setNumColumns(calculateNumColumns());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Calculate the number of rows
    const numRows = Math.ceil(data.length / numColumns);

    // Split the images into chunks to distribute them across rows and columns
    const imageChunks = [];
    for (let i = 0; i < numRows; i++) {
        imageChunks.push(data.slice(i * numColumns, (i + 1) * numColumns));
    }

    // Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    // Function to open modal
    const openModal = (index) => {
        setSelectedImageIndex(index);
        setModalOpen(true);
    };

    // Function to close modal
    const closeModal = () => {
        setSelectedImageIndex(null);
        setModalOpen(false);
    };

    // Function to move to next image
    const nextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % data.length);
    };

    // Function to move to previous image
    const prevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    };

    return (
        <div className={`gallery-parent-container ${className}`}>
            <div className="child-container">
                {imageChunks.map((chunk, rowIndex) => (
                    <div key={rowIndex} className="row-container">
                        {chunk.map((slide, colIndex) => (
                            // <img
                            //     key={rowIndex * numColumns + colIndex}
                            //     src={slide.imageUrl}
                            //     alt={slide.altText}
                            //     onClick={() => openModal(rowIndex * numColumns + colIndex)}
                            // />

                            <LazyLoadImage key={rowIndex * numColumns + colIndex}
                                           src={slide.imageUrl}
                                           alt={slide.altText}
                                           onClick={() => openModal(rowIndex * numColumns + colIndex)} />
                        ))}
                    </div>
                ))}
            </div>

            {modalOpen && (
                <div className="modal">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="modal-content">
                        {/*<img src={data[selectedImageIndex].imageUrl} alt={data[selectedImageIndex].altText} />*/}

                        <LazyLoadImage src={data[selectedImageIndex].imageUrl} alt={data[selectedImageIndex].altText} />
                        <button className="previous-button-modal" onClick={prevImage}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button className="next-button-modal" onClick={nextImage}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Gallery;
